body {
  background-color: #ecf0f1;
  margin: 0;
  padding: 0;
  height: 100%;
}

/*
 * override semantic.css styles
 *
 * remove the
 *   - horizontal margin
 *   - border radius
 * of the card-like sections in Mobile screen size.
 */

@media only screen and (max-width: 767px) {
  .ui.container {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .ui.top.attached.header, .ui.segment, .ui.message, .ui.card, .ui.cards>.card {
    border-radius: 0;
  }
}

/*
 * react-calendar-heatmap styles
 *
 * All of the styles in this file are optional and configurable!
 * The github and gitlab color scales are provided for reference.
 */

.react-calendar-heatmap text {
  font-size: 10px;
  fill: #aaa;
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
  font-size: 5px;
}

.react-calendar-heatmap rect:hover {
  stroke: #555;
  stroke-width: 1px;
}

/*
 * Default color scale
 */

.react-calendar-heatmap .color-empty {
  fill: #eeeeee;
}

.react-calendar-heatmap .color-filled {
  fill: #8cc665;
}

/*
 * Github color scale
 */

.react-calendar-heatmap .color-github-0 {
  fill: #E6E6E6;
}
.react-calendar-heatmap .color-github-1 {
  fill: #F6CED8;
}
.react-calendar-heatmap .color-github-2 {
  fill: #F7819F;
}
.react-calendar-heatmap .color-github-3 {
  fill: #FA5882;
}
.react-calendar-heatmap .color-github-4 {
  fill: #FE2E64;
}
.react-calendar-heatmap .color-github-5 {
  fill: #FF0040;
}
.react-calendar-heatmap .color-github-6 {
  fill: #B40431;
}
.react-calendar-heatmap .color-github-7 {
  fill: #610B21;
}
.react-calendar-heatmap .color-github-8 {
  fill: #2A0A12;
}
